/** @format */
import React, { useState } from "react";
import Layout from "../Components/Layout/Layout";
import Wrapper from "../Components/Banner/Banner.styled";
import homeLogo from "../images/homeTop.png";
import Banner from "../images/homebanner.png";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
function Home() {
  return (
    <Layout>
      <Wrapper>
        <div className="homeTop">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="homeTopSingleItem">
                  <div className="logo">
                    <img src={homeLogo} className="img-fluid" alt="logo" />
                  </div>
                  <div className="socialIcon">
                    <ul>
                      {/* single item  */}

                      <li>
                        <a href="facebook.com">
                          <Icon icon="simple-icons:opensea" />
                        </a>
                      </li>
                      {/* sinlge item end */}
                      <li>
                        <a href="facebook.com">
                          <Icon icon="jam:discord" />
                        </a>
                      </li>
                      {/* sinlge item end */}
                      <li>
                        <a href="facebook.com">
                          <Icon icon="akar-icons:twitter-fill" />
                        </a>
                      </li>
                      {/* sinlge item end */}
                      <li>
                        <a href="facebook.com">
                          <Icon icon="ion:logo-tiktok" />
                        </a>
                      </li>
                      {/* sinlge item end */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="homeBanner">
                    <img src={Banner} className="img-fluid" alt="banner" />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="welcomsintleItem">
                  <div className="border"></div>
                  <div className="welcomeTitle">
                    <h3>WELCOME TO THE</h3>
                  </div>
                  <div className="paddingSingleItem">
                    <div className="welcomeSubtitle">
                      <h1>LONE WOLF NETWORK</h1>
                    </div>
                    <div className="welcomePara">
                      <p>
                        Are you a hustler? A leader? Do you hunt your dreams and
                        goals all by yourself every day while everyone else
                        thinks you’re crazy?
                      </p>
                      <br />
                      <p>
                        Then welcome to the place where exactly these people are
                        brought together in a pack, in a Warrior network. It’s
                        all about getting in the Wolf Pack and thus your own
                        Personal Freedom and escaping the Rat Race.
                      </p>
                    </div>
                    <div className="welcomeButton">
                      <Link to="Main">Enter</Link>
                    </div>
                    <div className="welcomeArrow">
                      <a href="#">
                        <Icon icon="fa-solid:angle-double-down" />
                      </a>
                    </div>
                  </div>
                  <div className="border"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
}

export default Home;
