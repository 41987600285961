/** @format */

import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 120px;
  color: white;
  .networkSingleItem {
  }
  .networkContent {
    text-align: center;
    padding: 40px 0px;
  }
  .networkContent h3 {
    @media (max-width: 767px) {
      text-align: left;
    }
  }
  .networkContent p {
    font-size: 18px;
    @media (max-width: 767px) {
      text-align: left;
    }
  }
`;

export default Wrapper;
