/** @format */

import styled from "styled-components";

const Wrapper = styled.div`
  footer {
    padding: 20px 0px 60px 0px;
  }
  .formSingleItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  form {
  }
  .fieldGroup {
    display: flex;
    align-items: center;
    flex: 0 0 40%;
    max-width: 40%;
  }
  .field-input {
    min-height: 47px;
    background: transparent;
    border: 1px solid #686868;
    outline: 0;
    color: white;
    font-size: 18px;
    padding: 0px 20px;
  }
  .btn-submit {
    border-radius: 0;
    font-size: 16px;
    padding: 0px 20px;
    min-height: 50px;
    position: relative;
    left: -15px;
    outline: 0;
    border: 0;
    min-height: 47px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    font-style: italic;
    background-color: white;
  }
  label {
    color: white;
    font-size: 20px;
    margin-bottom: 20px;
    display: inline-block;
    font-weight: 300;
    font-style: italic;
  }
  ::placeholder {
    color: white;
  }
  .footerLogo {
  }
  .footerLogo img {
    width: 150px;
  }
  .footerContent {
    text-align: right;
  }
  .footerContent p {
    font-weight: 300;
    margin-bottom: 15px;
    font-style: italic;
  }
  .socialIcon {
    display: flex;
    justify-content: flex-end;
  }

  .socialIcon li {
    list-style: none;
  }
  .socialIcon li a {
    margin: 0px 5px;
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
  }
  @media (max-width: 991.99px) {
    .field-input {
      font-size: 14px;
      padding: 0px 4px;
    }
  }
  @media (max-width: 767px) {
    .formSingleItem {
      display: grid;
      grid-template-columns: 1fr;
    }
    .signup-form {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .fieldGroup {
      display: block;
      /* grid-template-columns: 1fr; */
      flex: 0 0 100%;
      max-width: 100%;
    }
    .field-input {
      width: 100%;
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .btn-submit {
      width: 100%;
      left: 0;
    }
    .footerLogo {
      text-align: center;
      margin: 40px 0px;
    }
    .footerContent {
      text-align: center;
    }
    .socialIcon {
      justify-content: center;
    }
  }
  @media (max-width: 1199.98px) {
    .container {
      max-width: 1030px;
    }
    .col-md-12 {
      padding: 0px 5px;
    }
  }

  @media (max-width: 991.98px) {
    .container {
      max-width: 770px;
    }
    .col-md-12 {
      padding: 0px 5px;
    }
  }
  @media (max-width: 767.98px) {
    .container {
      max-width: 590px;
    }
  }
  @media (max-width: 575.99px) {
    .col-md-12 {
      padding: 0px 8px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
    }
  }
`;

export default Wrapper;
