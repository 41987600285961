/** @format */

import styled from "styled-components";

const Wrapper = styled.div`
  color: white;
  padding: 50px 10px;
  .commonTitle {
    font-style: italic;
  }
  .content {
    margin-bottom: 20px;
  }
  .visionImg {
    margin: 20px 0px;
  }
  .content p {
    margin-bottom: 40px;
  }
  .cmnRow {
    justify-content: center;
  }
  .commonItem {
    padding: 40px 10px;
    border: 1px solid #dec263;
    min-height: 322px;
    margin-bottom: 40px;
  }
  .commonItem h3 {
    color: #dec263;
    font-family: "Poppins", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
  }
  .commonItem p {
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 31.333333%;
  }
  @media (max-width: 767.98px) {
    .col-md-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .commonItem {
      min-height: auto;
    }
    .commonTitle {
      text-align: center;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .col-md-4 {
      flex: 0 0 33.333333%;
      max-width: 30.333333%;
    }
    .commonItem p {
      font-size: 15px;

      line-height: 22px;
    }
    .commonItem {
      min-height: 465px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .commonItem {
      min-height: 404px;
    }
  }
`;

export default Wrapper;
