/** @format */

import styled from "styled-components";

const Wrapper = styled.div`
  color: white;
  padding: 50px 0px;
  .commonTitle {
    font-style: italic;
  }
  .content {
    margin-bottom: 20px;
  }
`;

export default Wrapper;
