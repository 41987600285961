import React from "react";
import Wrapper from "./Leader.styled";
import { Swiper, SwiperSlide } from "swiper/react";
import Don from "../../images/don.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
function Leader() {
  return (
    <Wrapper>
      <div className="leader" id="leader">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="commonTitle">The Team</h3>
            </div>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="col-md-4">
                  <div className="img">
                    <img src={Don} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content">
                    <h3>Mr. Don Wolf</h3>
                    <p>
                      Mr. Don Wolf. A Hustler who has made it his goal to bring
                      together all the Lone Wolfs of the entire planet and thus
                      create a network and project where the complete pack can
                      benefit from it. The vision is to create a whole new era
                      of a Hustlers Network with the Lone Wolf Network where the
                      Warriors of the world can come together and chase their
                      own personal dreams and goals as well as spread their
                      network all over the globe.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-4">
                  <div className="img">
                    <img src={Don} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content">
                    <h3>Mr. Don Wolf</h3>
                    <p>
                      Mr. Don Wolf. A Hustler who has made it his goal to bring
                      together all the Lone Wolfs of the entire planet and thus
                      create a network and project where the complete pack can
                      benefit from it. The vision is to create a whole new era
                      of a Hustlers Network with the Lone Wolf Network where the
                      Warriors of the world can come together and chase their
                      own personal dreams and goals as well as spread their
                      network all over the globe.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-4">
                  <div className="img">
                    <img src={Don} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content">
                    <h3>Mr. Don Wolf</h3>
                    <p>
                      Mr. Don Wolf. A Hustler who has made it his goal to bring
                      together all the Lone Wolfs of the entire planet and thus
                      create a network and project where the complete pack can
                      benefit from it. The vision is to create a whole new era
                      of a Hustlers Network with the Lone Wolf Network where the
                      Warriors of the world can come together and chase their
                      own personal dreams and goals as well as spread their
                      network all over the globe.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-4">
                  <div className="img">
                    <img src={Don} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content">
                    <h3>Mr. Don Wolf</h3>
                    <p>
                      Mr. Don Wolf. A Hustler who has made it his goal to bring
                      together all the Lone Wolfs of the entire planet and thus
                      create a network and project where the complete pack can
                      benefit from it. The vision is to create a whole new era
                      of a Hustlers Network with the Lone Wolf Network where the
                      Warriors of the world can come together and chase their
                      own personal dreams and goals as well as spread their
                      network all over the globe.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="col-md-12">
              <div className="border"></div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Leader;
