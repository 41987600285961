/** @format */

import styled from "styled-components";

const Wrapper = styled.div`
  color: white;
  .leader {
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: flex;
    flex-wrap: wrap;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 28.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 71.666667%;
    padding: 0px 15px;
  }
  .swiper {
    padding: 0 20px;
    margin-bottom: 70px;
  }
  .img img {
    border: 2px solid white;
    width: 90%;
    margin-top: 20px;
  }
  .content {
    margin-top: 40px;
  }
  .content h3 {
    font-size: 20px;
    color: #dec263;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .content p {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
  }

  @media (max-width: 991px) {
    .content {
      margin-top: 0px;
    }
    .img img {
      width: 100%;
      margin-top: 10px;
    }
    .content h3 {
      font-size: 19px;
      margin-bottom: 0;
    }
    .content p {
      font-size: 14px;
    }
  }
  @media (max-width: 575.98px) {
    .col-md-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-md-8 {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0px 0px;
    }
    .content {
      margin-top: 30px;
    }
  }
`;

export default Wrapper;
