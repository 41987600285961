import React, { useEffect, useState } from "react";
import Nft from "../../images/nft.gif";
import Wrapper from "./Lone.Styled";
import ReactDOM from "react-dom";
import moment from "moment";

function Lone() {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showSemicolon, setShowSemicolon] = useState(false);
  useEffect(() => {
    setInterval(() => {
      const now = moment();
      const then = moment("2020-10-15 12:12:12", "YYYY-MM-DD hh:mm:ss");
      const countdown = moment(then - now);
      setDays(countdown.format("D"));
      setHours(countdown.format("HH"));
      setMinutes(countdown.format("mm"));
      setSeconds(countdown.format("ss"));
    }, 1000);
  }, []);
  return (
    <Wrapper>
      <div className="lone" id="lone">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="LeftItem">
                <h3 className="commonTitle">GET YOUR LONE WOLF</h3>
                <p>
                  In order to join the Hustlers network and benefit from all
                  factors, your own Lone Wolf is the special and limited access
                  to it. Thus, you invest your recurring and diminishing Fiat
                  not only in an NFT, no, but in the eternal key to a network
                  full of coughers, full of people who have only one goal – to
                  achieve your dreams and goals as quickly and safely as
                  possible, no matter what everyone else says! Be ready and mint
                  your own Lone Wolf, your access to personal freedom
                </p>
              </div>
              <div className="timer-container">
                <div className="timer">
                  <span className="numeric"> {days}</span>
                  <br />
                  <span className="alpha">days</span>
                </div>
                {showSemicolon ? <div className="semicolon">:</div> : null}
                <div className="timer">
                  <span className="numeric"> {hours}</span>
                  <br />
                  <span className="alpha">hour</span>
                </div>
                {showSemicolon ? <div className="semicolon">:</div> : null}
                <div className="timer">
                  <span className="numeric"> {minutes}</span>
                  <br />
                  <span className="alpha">minute</span>
                </div>
                {showSemicolon ? <div className="semicolon">:</div> : null}
                <div className="timer">
                  <span className="numeric"> {seconds}</span>
                  <br />
                  <span className="alpha">second</span>
                </div>
              </div>
              <div className="button">
                <a href="mailto:samirhossain">Get Your Whishlist</a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="rightItem">
                <div className="video">
                  <img src={Nft} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="border"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Lone;
