/** @format */

import styled from "styled-components";

const Wrapper = styled.nav`
  min-height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 587;
  background: black;
  @media (max-width: 992px) {
    position: fixed;
    justify-content: space-between;
    padding: 0 50px;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
  }
  .nav-logo {
    display: flex;
    justify-content: center;
    transition: 0.5s ease-in-out;
    margin-top: 20px;
    margin-bottom: 20px;

    img {
      width: 153px;
    }
  }
  .nav-link-container {
    display: flex;
    @media (max-width: 992px) {
      position: fixed;
      left: 0%;

      top: 18%;
      width: 100%;
      height: 0;
      overflow: hidden;
      background: black;
      transition: 0.5s ease-out;
    }
    .menu {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media (max-width: 992px) {
        flex-direction: column;
        margin-left: 50px;
        margin-right: 50px;
        width: 100%;
        padding-bottom: 20px;
        margin: 30px 0px;
      }
      li {
        margin: 0 15px;
        position: relative;
        @media (max-width: 1199.98px) {
          margin: 0 8px;
        }
      }
      @media (max-width: 992px) {
        width: 100%;
      }
      a {
        text-decoration: none;
        color: #ffffff;
        width: 100%;
        position: relative;

        @media (max-width: 992px) {
          text-align: center;
        }
      }
    }
    .nav-link-last-item {
      margin-right: 20px;
      @media (max-width: 992px) {
        margin: 0;
      }
    }
    .social-media-icon {
      display: flex;
      margin-top: 12px;
      @media (max-width: 992px) {
        justify-content: center;
      }
      a {
        margin: 0 5px;
        font-size: 24px;
      }
    }
    .nav-button {
      @media (max-width: 992px) {
        text-align: center;
      }
      a {
        background: #e16589;
        padding: 9px 25px;
        border-radius: 8px;
        border: 1px solid #e16589;
        box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.25);
        transition: 0.5s ease-in-out;

        :hover {
          background-color: transparent;
          border: 1px solid #e16589;
        }
      }
    }
  }

  .mobile-menu {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      button {
        border: none;
        color: #ffffff;
        font-size: 30px;
        background: transparent;
        z-index: 592;
      }
    }
  }
  .navLink a {
    ::before {
      left: 0px;
      right: 0px;
      top: -15px;

      content: "";
      width: 0%;
      position: absolute;
      height: 2px;
      background-color: #dec263;
      transition: 0.5s ease;
    }
    ::after {
      left: 0px;
      right: 0px;

      content: "";
      width: 0%;
      bottom: -15px;
      position: absolute;
      height: 2px;
      background-color: #dec263;
      transition: 0.5s ease;
    }
    :hover::before {
      width: 100%;
    }
    :hover::after {
      width: 100%;
    }
  }
`;
export default Wrapper;
