export const RoadmapData = [
  {
    id: "45334",
    descriptionTitle: " THE LONE WOLF NETWORK",
    description:
      "In this phase, the Lone Wolf Network, the project 0 of the complete vision will be brought into the world and the limited Wolf Pack is determined by the first sale and Mint. Furthermore, in the phase of project zero, after the first 10,000 Lone Wolfs have been found, the goal is to recruit new members of the pack in the pack by selling on OpenSea. But never forget Hustler - The active Lone Wolf Holders will always be in front!",
  },
  {
    id: "tfdsfd4",
    descriptionTitle: "AGIFTS FOR THE PACK",
    description:
      "This small intermediate phase will be activated as soon as Mr. Don Wolf sees the Lone Wolf Network as successful and established. There, the pack is simply shown thanks with a giveaway of 40 stored special Lone Wolfs. Long Holder Wolves or good Recruiters Wolves have of course attracted the attention of the leader of the pack and not only have a higher chance of getting a gift of the pack but also becoming a member of the narrow circle of the Lone Wolf Network.",
  },
  {
    id: "46ty",
    descriptionTitle: "The Hustlers Pub",
    description:
      "The phase in which a very big step of the vision of the pack is accomplished. The creation of The Hustlers Pub in metaverse to bring the network factor of the Wolf Pack even closer. There is the possibility to be a part of talks, events or even a meeting of the inner circle of the pack. The special access to the Hustlers Pub and all the benefits of the vision of the pack are always granted only to the active Lone Wolf Holders. Good holders will always be at the forefront.",
  },
  {
    id: "h75",

    descriptionTitle: "The Lone Wolf Network II",
    description:
      "This phase is about really bringing the Hustlers together! The Lone Wolf Network II Collection produces a limited number of Non Fungible Tokens, only for active Lone Wolf Holders, which is also access to the Lone Wolf Network Convention. - The place where the pack really comes together.",
  },
  {
    id: "o867",

    descriptionTitle: "The Hustle Mountains",
    description:
      "The phase of the pack's vision where the own world was to be created for the Lone Wolfs. The place where they have the opportunity to benefit from their active holding and chase their personal freedom through the various factors in their own metaverse!",
  },
  {
    id: "8rcf",

    descriptionTitle: "The Lone Wolf Vehicles",
    description:
      "Another phase of vision, another innovation. With the Lone Wolf Vehicles, the pack brings another limited collection of Non Fungible Tokens to the pack and the opportunity to get your own Lone Wolf the right vehicle and to benefit from a variety of factors.",
  },
  {
    id: "75h",

    descriptionTitle: "Non stop innovation",
    description:
      "Continuous growth is the most important thing for the Hustlers Pack and therefore the Non stop Innovation phase is intended for the inner members of the pack generated over time. Long holders or good recruiters will sooner or later be accepted by the leader into the inner circle of the Wolf Pack and benefit from a variety of factors. Among other things, in this phase of the fact that they are allowed to have a complete say in the path of the next point of the vision.",
  },
];
