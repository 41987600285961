/** @format */
import styled from "styled-components";

const Wrapper = styled.div`
  .homeTop {
  }
  .homeTopSingleItem {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 1fr 1fr;
  }
  .logo {
    position: relative;
    left: 13%;
    top: 44%;

    text-align: right;
  }
  .logo img {
    width: 163px;
  }
  .socialIcon {
  }
  .socialIcon ul {
    justify-content: flex-end;
    display: flex;
  }

  .socialIcon ul li {
    list-style: none;
  }
  .socialIcon ul li a {
    margin: 0px 5px;
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
    display: block;
    animation: pulse-animation 1s linear infinite;
    animation-play-state: paused;
  }
  @keyframes pulse-animation {
    25% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(0.9);
    }
  }
  .socialIcon ul li a:hover {
    animation-play-state: running;
  }
  .homeBanner {
  }
  .homeBanner img {
  }
  .welcomsintleItem {
    text-align: center;
    padding: 30px 0px;
  }

  .border {
    border: 1px solid white;
  }
  .welcomeTitle {
    position: relative;
    top: -13px;
  }
  .welcomeTitle h3 {
    background: black;
    display: inline-block;
    padding: 0px 18px;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
  }
  .welcomeSubtitle {
    padding: 15px 0px;
  }
  .welcomeSubtitle h1 {
    font-weight: 700;
    font-style: italic;
    font-size: 28px;
  }
  .welcomePara {
  }
  .welcomePara p {
    line-height: 22px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 30px;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
  }
  .welcomeButton {
  }
  .welcomeButton a {
    font-size: 17px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-style: italic;
    fill: #000000;
    color: #000000;
    background-color: #d5ce8c;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #ffffff;
    border-radius: 15px 15px 15px 15px;
    padding: 12px 45px 12px 45px;
    text-decoration: none;
    transition: 0.5s ease-in-out;
    transform: scale(1);
    display: inline-block;
    text-transform: uppercase;
  }
  .welcomeButton a:hover {
    transform: scale(0.9);
    background-color: white;
  }
  .welcomeArrow {
    animation: up-down 1.5s ease infinite;
    transform: translate(0);
    display: inline-block;
    padding: 50px 0px;
  }
  .welcomeArrow a {
    color: #d5ce8c;
    font-size: 48px;
  }
  @keyframes up-down {
    0% {
      transform: translate(0);
    }

    50% {
      transform: translate(0, 40px);
    }
    100% {
      transform: translate(0);
    }

    25% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(1);
    }
  }
  .paddingSingleItem {
    padding: 0px 70px;
  }

  @media (max-width: 1199.98px) {
    .container {
      max-width: 1030px;
    }
    .col-md-12 {
      padding: 0px 5px;
    }
    .paddingSingleItem {
      padding: 0px 80px;
    }
  }

  @media (max-width: 991.98px) {
    .container {
      max-width: 770px;
    }
    .col-md-12 {
      padding: 0px 5px;
    }
    .paddingSingleItem {
      padding: 0px 92px;
    }
  }
  @media (max-width: 767.98px) {
    .container {
      max-width: 590px;
    }
    .col-md-12 {
      padding: 0px 5px;
    }
    .socialIcon ul {
      display: none;
    }
    .homeTopSingleItem {
      grid-template-columns: 1fr;
      margin-top: -20px;
    }
    .logo {
      position: relative;
      left: 0;
      top: 42%;
      text-align: center;
    }
  }
  @media (max-width: 575.99px) {
    .col-md-12 {
      padding: 0px 5px;
    }
    .socialIcon ul {
      display: none;
    }
    .homeTopSingleItem {
      grid-template-columns: 1fr;
      margin-top: -20px;
    }
    .logo {
      position: relative;
      left: 0;
      top: 42%;
      text-align: center;
    }
    .logo img {
      width: 145px;
    }
    .paddingSingleItem {
      padding: 0px 10px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
    }
  }
`;

export default Wrapper;
