import React from "react";
import Wrapper from "./Vision.Styled";
import Bg from "../../images/Bg.png";
function Vision() {
  return (
    <Wrapper>
      <div className="vision" id="vision">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="singleItem">
                <div className="content">
                  <h3 className="commonTitle">VISION</h3>
                  <p>
                    The vision of the Lone Wolf Network is bigger than you can
                    imagine. It is based on the thoughts of the leader of the
                    pack, Mr. Don Wolf. A man who has made it his goal to bring
                    the Lone Wolfs of the whole world together in one pack to
                    create the Hustlers network. However, in the Lone Wolf
                    Network the factor of the pack is in the foreground and it
                    is the goal to mine and implement the great vision of the
                    network together. The Lone Wolf Network itself is just the
                    beginning of something big. All active members and Non
                    Fungible Token Holders of the pack will always be at the
                    forefront and benefit from the strong vision of the wolf
                    pack!
                  </p>
                </div>
                <div className="visionImg">
                  <img src={Bg} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="line"></div>
    </Wrapper>
  );
}

export default Vision;
