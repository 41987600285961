/** @format */

import React from "react";
import { HashLink } from "react-router-hash-link";
import Wrapper from "./Faq.styled";

function Faq() {
  // accordion open function
  function handleAccordion(e) {
    e.target.classList.toggle("active");
    let panel = e.target.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  return (
    <Wrapper>
      <div className="container" id="faq">
        <div className="faq">
          <div className="left-section">
            <h3 className="commonTitle">FAQ'S</h3>
          </div>
          <div className="faqs-container" id="faq">
            <div className="container-row">
              <div className="row-col">
                <div className="accordion-container">
                  <button className="accordion-item" onClick={handleAccordion}>
                    When can I buy an Bay Club NFT?
                  </button>
                  <div className="panel">
                    <p>
                      TBD (Around 3nd Week April WL is about to start, followed
                      by publicsale and Reveal). Check Socials
                    </p>
                  </div>
                </div>
                <div className="accordion-container">
                  <button className="accordion-item" onClick={handleAccordion}>
                    What will an Bay Club NFT enable me to do as a holder?
                  </button>
                  <div className="panel">
                    <p>
                      With a platinum membership you will be invited to request
                      many of the following concierge services. Sold out sports
                      events, VIP night clubs and bottle service, shoes you
                      cannot get? These are all outdated . Ultra Rare Collection
                      8 keys Super rare collection 4 keys Rare &amp; uniq ue
                      collection 2 keys The Boys ( (*) keys Monthly use your
                      keys on select concierge services Limited Membership s
                      1000
                    </p>
                  </div>
                </div>
                <div className="accordion-container" onClick={handleAccordion}>
                  <button className="accordion-item">
                    Can I own more than one Bay Club NFT?
                  </button>
                  <div className="panel">
                    <p>
                      Yes you can own more than one TNA NFT, The long-term
                      benefit is definitely appreciation of valuation.
                    </p>
                  </div>
                </div>
                <div className="accordion-container" onClick={handleAccordion}>
                  <button className="accordion-item">
                    How much does it cost to buy one Bay Club NFT?
                  </button>
                  <div className="panel">
                    <p>it will be 0.033 per nft </p>
                  </div>
                </div>

                <div className="accordion-container" onClick={handleAccordion}>
                  <button className="accordion-item">
                    Are there additional fees, such as gas fees?
                  </button>
                  <div className="panel">
                    <p>
                      We are trying to build our smart contracts to optimize gas
                      prices and also hosting minting from our own server which
                      further reduces 20% gas price. Also our masterpiece
                      Algorithm is going to look for the volatility of price and
                      always pick the best lower gas fee for your transaction.
                      So overall, we standout to reduce 30-40% gas fees compare
                      to all other NFT projects.
                    </p>
                  </div>
                </div>

                <div className="accordion-container" onClick={handleAccordion}>
                  <button className="accordion-item">
                    Can I sell my Bay Club NFT for a profit?
                  </button>
                  <div className="panel">
                    <p>
                      Yes you can, but we recommend to hold and enjoy the true
                      benefits in the long run.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border"></div>
      </div>
    </Wrapper>
  );
}

export default Faq;
