import React from "react";
import Wrapper from "../Components/Home/Main.styled";
import Faq from "../Components/Faq/Faq";
import Layout from "../Components/Layout/Layout";
import Navbar from "../Components/Navbar/Navbar";
import Lone from "../Components/Lone/Lone";
import Vision from "../Components/Vision/Vision";
import Pack from "../Components/Pack/Pack";
import Utilities from "../Components/Utilities/Utilities";
import Leader from "../Components/Leader/Leader";
import NetworkBg from "../images/netwrok.png";
import Roadmap from "../Components/Roadmap/Roadmap";
function Main() {
  return (
    <Layout>
      <Wrapper>
        <Navbar />
        <div className="network" id="network">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="networkSingleItem">
                  <div className="networkImg">
                    <img src={NetworkBg} className="img-fluid" alt="bg" />
                  </div>
                  <div className="networkContent">
                    <h3 className="commonTitle">
                      WELCOME TO THE LONE WOLF NETWORK
                    </h3>
                    <p>
                      The Lone Wolf Network. Where the values of loyalty,
                      respect and honesty in addition to the factor of hard work
                      to achieve his dreams and goals are very important. The
                      Wolf Pack is limited to 10,000 unique Non Fungible Tokens,
                      which are also the entry into the Hustlers network, the
                      entry into freedom. In this limited network, not only the
                      hustlers are brought together to benefit from the network
                      factor. No, the network offers the Lone Wolfs the
                      opportunity to give each other tips specifically in
                      different areas in order to quickly reach the financial as
                      well as general personal freedom. Choose the path of
                      success now and escape the 9 to 5 Rat Race. Create your
                      own personal freedom!
                    </p>
                  </div>
                </div>
                <div className="border"></div>
              </div>
            </div>
          </div>
        </div>
        <Lone />
        <Vision />
        <Roadmap />
        <Pack />
        <Utilities />
        <Leader />

        <Faq />
      </Wrapper>
    </Layout>
  );
}

export default Main;
