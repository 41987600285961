/** @format */

import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";

import Main from "./Pages/Main";

function App() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="Main" element={<Main />} />
    </Routes>
  );
}

export default App;
