/** @format */

import React, { useEffect, useState } from "react";
import Wrapper from "./Navbar.styled";
import { Icon } from "@iconify/react";
import { NavHashLink } from "react-router-hash-link";
import Logo from "../../images/homeTop.png";

function Navbar() {
  const [mobileMenu, setMobileMenu] = useState(false);
  // window resize hook
  useEffect(() => {
    // window size hook for sidenav
    function checkScreenSize() {
      if (window.innerWidth > 992) {
        setMobileMenu(false);
      }
    }
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, [setMobileMenu]);

  // for mobile sidenav display
  useEffect(() => {
    const navContainer = document.querySelector(".nav-link-container");
    if (mobileMenu) {
      navContainer.style.height = 450 + "px";
    } else {
      navContainer.style.height = 0 + "px";
    }
  }, [mobileMenu]);

  // scroll function
  useEffect(() => {
    let navigation = document.querySelector("header");
    window.addEventListener("scroll", scrollFunc);

    function scrollFunc() {
      if (window.innerWidth) {
        if (
          document.body.scrollTop > 80 ||
          document.documentElement.scrollTop > 80
        ) {
          navigation.style.background = " #290b31";
        } else {
          navigation.style.background = "transparent";
        }
      } else {
        navigation.style.background = "#030b21";
      }
    }
  }, []);

  // scroll with offset
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <Wrapper>
      <div className="nav-logo">
        <img src={Logo} className="img-fluid" alt="logo" />
      </div>{" "}
      <div className="nav-link-container">
        <ul className="menu">
          <li className="navLink">
            <NavHashLink
              to="#lone"
              scroll={(el) => scrollWithOffset(el)}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Get Your Lone Wolf{" "}
            </NavHashLink>{" "}
          </li>{" "}
          <li className="navLink">
            <NavHashLink
              to="#vision"
              scroll={(el) => scrollWithOffset(el)}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Vision{" "}
            </NavHashLink>{" "}
          </li>{" "}
          <li className="navLink">
            <NavHashLink
              to="#roadmap"
              scroll={(el) => scrollWithOffset(el)}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Roadmap
            </NavHashLink>
          </li>{" "}
          <li className="navLink">
            <NavHashLink
              to="#pack"
              scroll={(el) => scrollWithOffset(el)}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              The Hustler Pack{" "}
            </NavHashLink>{" "}
          </li>{" "}
          <li className="navLink">
            <NavHashLink
              to="#leader"
              scroll={(el) => scrollWithOffset(el)}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Team{" "}
            </NavHashLink>
          </li>
          <li className="navLink">
            <NavHashLink
              to="#faq"
              scroll={(el) => scrollWithOffset(el)}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Faq{" "}
            </NavHashLink>
          </li>
          <li className="nav-link-last-item navLink">
            <NavHashLink
              to="#network"
              scroll={(el) => scrollWithOffset(el)}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Network
            </NavHashLink>{" "}
          </li>
          <li className="social-media-icon">
            <a href="https://www.facebook.com">
              <Icon icon="simple-icons:opensea" />
            </a>
            <a href="https://www.facebook.com">
              <Icon icon="jam:discord" />
            </a>
            <a href="https://www.facebook.com">
              <Icon icon="akar-icons:twitter-fill" />
            </a>
            <a href="https://www.facebook.com">
              <Icon icon="ion:logo-tiktok" />
            </a>
          </li>
        </ul>{" "}
      </div>{" "}
      <div className="mobile-menu">
        <button
          onClick={() => {
            setMobileMenu(!mobileMenu);
          }}
        >
          {" "}
          {mobileMenu ? (
            <Icon icon="akar-icons:cross" />
          ) : (
            <Icon icon="uim:bars" />
          )}{" "}
        </button>{" "}
      </div>{" "}
    </Wrapper>
  );
}

export default Navbar;
