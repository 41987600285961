import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 100px;
  font-family: "Saira Condensed", sans-serif;
  letter-spacing: 1px;
  @media (max-width: 992px) {
    margin-top: 30px;
  }
  .roadmap-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    @media (max-width: 800px) {
      width: 100%;
    }
    .line {
      position: absolute;
      left: 33px;
      top: 7%;
      bottom: 0;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 3px;
      background-color: rgba(255, 255, 255, 0.15);
      z-index: 1;
      max-height: 85%;
      @media (max-width: 575px) {
        left: 24px;
        top: 4%;
        bottom: 0;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 3px;
        background-color: rgba(255, 255, 255, 0.15);
        z-index: 1;
        max-height: 75%;
      }
      .inner-line {
        height: 0%;
        background-color: #dec263;
        @media (max-width: 800px) {
        }
      }
    }
    .roadmap {
      display: block;
      margin-bottom: 30px;

      opacity: 0.5;
      @media (max-width: 800px) {
        flex-direction: column;
        text-align: center;
        margin-bottom: 0;
      }
      :nth-child(odd) {
        @media (max-width: 800px) {
          flex-direction: column-reverse;
        }
        h3 {
          margin-bottom: 20px;
          font-family: "Poppins", Sans-serif;
          font-weight: 600;
          font-style: italic;
          color: #dec263;
          font-size: 1.25rem;
        }
      }
      > div {
        min-width: 0;
        width: 91%;
        padding: 25px 40px;
        margin: 0 15px;
        margin-left: 90px;

        @media (max-width: 1199px) {
          width: 90%;
        }
        @media (max-width: 991px) {
          width: 86%;
        }
        @media (max-width: 767px) {
          width: 80%;
        }
        @media (max-width: 575px) {
          margin-left: 50px;
          padding: 25px 10px;
        }
      }
      .card-left {
        position: relative;

        ::after {
          content: url("https://api.iconify.design/la/wolf-pack-battalion.svg?width=30&height=30");
          position: absolute;
          left: 0;
          top: 0px;
          width: 40px;
          height: 40px;
          transform: translate(-25px, 0px);
          border-radius: 50%;
          background: #dec263;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 800px) {
          }
        }
        :nth-child(odd) {
          text-align: right;
          @media (max-width: 800px) {
            text-align: center;
          }
          ::after {
            left: 0;
            right: 0;
            -webkit-transform: translate(-76px, 0px);
            -ms-transform: translate(-76px, 0px);
            transform: translate(-76px, 20%);
            top: 200%;
            @media (max-width: 575px) {
              transform: translate(-46px, 20%);
              top: 200%;
            }
          }
          @media (max-width: 991px) {
            transform: translate(0px, 100%);
          }
        }
        h3 {
          font-size: 45px;
          font-family: "Saira Condensed", sans-serif;
        }
      }
      .card-right {
        background: transparent;
        border-radius: 10px;
        border: 1px solid #dec263;
        p {
          font-family: "Poppins", Sans-serif;
          font-weight: 300;
          color: #ffffff;

          @media (max-width: 450px) {
            font-size: 16px;
          }
          strong {
            font-size: 30px;
            @media (max-width: 450px) {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
`;

export default Wrapper;
