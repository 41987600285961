import React from "react";
import Wrapper from "./Pack.Styled";
import Bg from "../../images/pack.png";
function Pack() {
  return (
    <Wrapper>
      <div className="vision" id="pack">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="singleItem">
                <h3 className="commonTitle">THE HUSTLERS PACK</h3>
                <div className="visionImg">
                  <img src={Bg} className="img-fluid" alt="" />
                </div>
                <div className="content">
                  <p>
                    The Lone Wolf Network Hustlers Pack. Anyone who has held a
                    Lone Wolf for an indefinite period of time and verified it
                    in the Discord server of the Wolf Pack is granted eternal
                    access to the Hustlers network! However, the entire network
                    is always only 10,000 active Lone Wolf Holders who will
                    always be at the forefront, who will always benefit most
                    from the innovation of the pack. But also good recruiters
                    are deposited and noted in the Wolf Pack. Because if you own
                    a lot of Lone Wolfs and successfully sell them again, a new
                    Hustler is recruited every time with the aim of achieving
                    your own personal freedom. And this should not go unnoticed
                    under any circumstances.
                  </p>
                </div>
              </div>
              <div className="border"></div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Pack;
