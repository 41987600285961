import React from "react";
import Wrapper from "./Utilities.Styled";
import Bg from "../../images/utilities.png";
function Utilities() {
  return (
    <Wrapper>
      <div className="vision" id="utilities">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="singleItem">
                <h3 className="commonTitle">UTILITIES</h3>
                <div className="visionImg">
                  <img src={Bg} className="img-fluid" alt="" />
                </div>
                <div className="content">
                  <p>
                    The Lone Wolf Network offers a wide variety of opportunities
                    to grow personally and thus realize your dreams and goals.
                    Each area is based on the Warrior Network of the Lone Wolfs
                    of the Pack, who support each other to escape the 9 to 5 Rat
                    Race and realize their dreams and goals.
                  </p>
                </div>
              </div>
              <div className="row cmnRow">
                <div className="col-md-4">
                  <div className="commonItem">
                    <h3>The Hustlers Network</h3>
                    <p>
                      The Wolf Pack brings a complete and structured network
                      where the members of the Pack can live out their Warrior
                      Mindset completely and not only network together but also
                      pursue their own personal freedom in sectors.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="commonItem">
                    <h3>The Hustlers Network</h3>
                    <p>
                      The complete pack will always consist of only 10,000
                      unique and limited project zero, Lone Wolfs and thus bring
                      up the complete value of your own Lone Wolf in case of
                      success of the Lone Wolf Network and make the Non Fungible
                      Token very popular.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="commonItem">
                    <h3>The Hustlers Network</h3>
                    <p>
                      Another crucial point of a great opportunity to benefit
                      from the network is the great vision of the complete Wolf
                      Pack and Mr. Don Wolf. The entire team does everything in
                      its power to successfully implement these goals and to let
                      every active Lone Wolf Holder benefit as much as possible.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border"></div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Utilities;
