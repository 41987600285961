/** @format */
import { useState } from "react";
import Wrapper from "./Footer.styled";
import { Icon } from "@iconify/react";
import Logo from "../../images/homeTop.png";
function Footer() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  });

  function submitHandler(event) {
    event.preventDefault();
    console.log(formData);
  }

  function inputChangeHandler(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }
  return (
    <Wrapper>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="formSingleItem">
                <form className="signup-form" onSubmit={submitHandler}>
                  <label className="field-label" id="email">
                    JOIN THE PACK
                  </label>

                  <div className="fieldGroup">
                    <div className="field">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="field-input"
                        value={formData.email}
                        onChange={inputChangeHandler}
                      />
                    </div>

                    <button type="submit" className="btn-submit">
                      Subscribe
                    </button>
                  </div>
                </form>
                <div className="footerLogo">
                  <img src={Logo} className="img-fluid" alt="" />
                </div>
                <div className="footerContent">
                  <p>Lone Wolf Network Terms & Conditions</p>
                  <p>The Lone Wolf Network 2022, all rights reserved</p>

                  <ul className="socialIcon">
                    {/* single item  */}

                    <li>
                      <a href="facebook.com">
                        <Icon icon="simple-icons:opensea" />
                      </a>
                    </li>
                    {/* sinlge item end */}
                    <li>
                      <a href="facebook.com">
                        <Icon icon="jam:discord" />
                      </a>
                    </li>
                    {/* sinlge item end */}
                    <li>
                      <a href="facebook.com">
                        <Icon icon="akar-icons:twitter-fill" />
                      </a>
                    </li>
                    {/* sinlge item end */}
                    <li>
                      <a href="facebook.com">
                        <Icon icon="ion:logo-tiktok" />
                      </a>
                    </li>
                    {/* sinlge item end */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Wrapper>
  );
}

export default Footer;
