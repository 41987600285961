/** @format */

import styled from "styled-components";

const Wrapper = styled.div`
  color: white;
  .row {
    padding: 70px 0px;
  }
  video {
    max-width: 100%;
    width: 100%;
    margin: 0;
    line-height: 1;
    border: none;
    border-radius: 20px;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 63.666667%;
  }
  .commonTitle {
    text-align: center;

    font-style: italic;
  }
  .timer-container {
    display: grid;
    column-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 40px;
  }
  .timer {
    text-align: center;
    border: 1px solid white;
    padding: 20px 0px;
  }
  .numeric {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0px;
    display: block;
    font-family: "Poppins", sans-serif;
    margin-bottom: -20px;
  }
  .alpha {
    font-size: 18px;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
  }
  .button {
    margin-top: 50px;
  }
  .button a {
    background: #dec263;
    color: black;
    padding: 8px 30px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
  }
  @media (max-width: 991px) {
    .col-md-8 {
      -webkit-flex: 0 0 66.666667%;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 61.666667%;
    }
  }
  @media (max-width: 767px) {
    .col-md-8,
    .col-md-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .video {
      margin: 20px auto;
      text-align: center;
    }
    .row {
      flex-direction: column-reverse;
    }
    video {
      width: 70%;
    }
  }
`;

export default Wrapper;
