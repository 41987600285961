import React, { useEffect } from "react";
import { RoadmapData } from "./Roadmap.data";
import Wrapper from "./Roadmap.styled";

function Roadmap() {
  // scroll spy function
  useEffect(() => {
    function handleScroll() {
      let elem = document.querySelector("#line");
      let r = document.querySelectorAll(".roadmap");
      let elem2 = document.querySelector(".inner-line");
      let ycor = elem.getBoundingClientRect();
      let celValue = ycor.top;
      if (celValue < 400) {
        elem2.style.height = -celValue / 15 + "%";
      } else {
        elem2.style.height = 0 + "%";
      }
      // roadmap card opacity function
      r.forEach((item) => {
        let t = item.getBoundingClientRect();
        let v = t.top;
        if (v < 400) {
          item.style.opacity = "1";
        } else {
          item.style.background = "0.5";
        }
      });
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title" id="roadmap">
              <h2 className="commonTitle">ROADMAP</h2>
            </div>
            <div className="roadmap-container">
              <div className="line">
                <div className="inner-line"></div>
              </div>
              {RoadmapData.map((item, index) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="roadmap" key={item.id}>
                      <div className="card-left"></div>
                      <div className="card-right">
                        <h3>{item.descriptionTitle}</h3>
                        <p> {item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="border"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Roadmap;
